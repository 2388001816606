<template>
  <div class="container">
    <template v-if="!loading && !empty">
      <div class="top_container">设备详情</div>
      <div v-if="device.is_enable === 1"  class="btn_container" @click="addDeviceOrder">
        <a-icon type="plus" />
        <span>设备报修</span>
      </div>
      <div v-if="device.avatar" class="product_container">
        <img :src="device.avatar">
      </div>
      <div class="desc_container">
        <div class="title">{{ device.name }}</div>
        <div class="desc_item">
          <div class="name">设备编号</div>
          <div class="value">{{ device.code }}</div>
        </div>
        <div class="desc_item">
          <div class="name">设备类别</div>
          <div class="value">
            {{ device.big_category ? device.big_category.name : '' }}
            {{ device.small_category ? '/' + device.small_category.name : '' }}
          </div>
        </div>
        <div class="desc_item">
          <div class="name">状态</div>
          <div class="value">
            <div style="display: flex;align-items: center;height: 100%">
              <a-tag v-if="device.is_enable === 1" color="blue">启用</a-tag>
              <a-tag v-else color="red">禁用</a-tag>
            </div>
          </div>
        </div>
        <extend-field-list :field-list="device.fieldValueList"></extend-field-list>
        <div v-if="device.resp_user" class="user_container">
          <div class="user_info">
            <img :src="device.resp_user.avatar ? device.resp_user.avatar : DefaultAvatar">
            <div class="info">
              <div style="color: #000">{{ device.resp_user.real_name }}</div>
              <a style="color: #3263f2" :href="'tel:' + device.resp_user.mobile">{{ device.resp_user.mobile }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_container">
        <div class="current">
          <a-icon type="appstore" />
          <span>设备详情</span>
        </div>
        <div @click="inspectionPlanHistory">
          <a-icon type="interaction" />
          <span>巡检历史</span>
        </div>
        <div @click="deviceOrderHistory">
          <a-icon type="tool" />
          <span>维修历史</span>
        </div>
      </div>
    </template>
    <div class="loading" v-if="loading"><a-icon type="loading" /></div>
    <div class="empty_container" v-if="empty">
      <img src="@/assets/image/empty.png" alt="">
      <span>此二维码未绑定设备</span>
    </div>
  </div>
</template>
<script>
import DefaultAvatar from '@/assets/image/default_avatar.png'
import ExtendFieldList from '@/components/ExtendField/list'
import request from '@/utils/request';
export default {
  components: { ExtendFieldList },
  data () {
    return {
      loading: true,
      device: {},
      extendField: [],
      qrId: 0,
      DefaultAvatar,
      empty: false
    }
  },
  created: function () {
    const qrId = this.$route.query.qr_id
    if (!qrId) {
      return
    }
    this.qrId = qrId
    request({
      url: 'device/page_detail',
      data: { qr_id: qrId }
    }).then(r => {
      if (r.data.data.id) {
        this.device = r.data.data
      } else {
        this.empty = true
      }
      this.loading = false
    })
  },
  methods: {
    addDeviceOrder () {
      this.$router.push({ path: '/ado', query: {
          device_id: this.device.id,
          qr_id: this.qrId
      }})
    },
    deviceOrderHistory () {
      this.$router.replace({ path: '/doh', query: {
        device_id: this.device.id,
        qr_id: this.qrId
      }})
    },
    inspectionPlanHistory () {
      this.$router.replace({ path: '/iph', query: {
        device_id: this.device.id,
        qr_id: this.qrId
      }})
    },
    addFeedback () {
      this.$router.push({ path: '/af', query: {
        enterpriseId: this.single.enterprise.id,
        customerId: this.single.customer_id,
        singleProductId: this.single.id,
        qrId: this.qrId
      }})
    }
  }
}
</script>
<style lang="less">
.ant-drawer-body{
  height: 200px;
  overflow: auto;
  padding: 12px;
}
.profile_container{
  padding: 10px;
  overflow: hidden;
  word-wrap:break-word;
  word-break:break-all;
  img{
    max-width: 100% !important;
  }
  p{
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.container{
  padding: 8px;
  padding-bottom: 60px;
  padding-top: 58px;
  .product_container{
    font-size: 16px;
    margin-bottom: 8px;
    padding: 8px;
    background-color: #fff;
    img{
      width: 100%;
    }
  }
  .desc_container{
    margin-bottom: 8px;
    background-color: #fff;
    .title{
      padding-left: 10px;
      line-height: 44px;
      font-size: 20px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #e6e6e6;
    }
    .desc_item{
      display: flex;
      padding: 10px 0 10px 14px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      .name{
        color: #999;
        width: 80px;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 8px;
      }
      .value{
        color: #222;
        flex: 1;
        overflow: hidden;
        word-wrap:break-word; white-space:normal; word-break:break-all;
      }
    }
  }
  .user_container{
    padding: 14px;
    display: flex;
    justify-content: space-between;
    .user_info{
      display: flex;
      font-size: 18px;
      img{
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 2px;
      }
    }
    .job{
      padding: 0 10px;
      font-size: 12px;
      background-color: #eeeffe;
      display: inline-flex;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 10px;
      color: #3263f2;
    }
  }
}
.feedback_type_container{
  display: flex;
  flex-wrap: wrap;
  div {
    background-color: #3263f2;
    color: #fff;
    border-radius: 10px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    padding: 0 16px;
    margin-right: 6px;
    margin-bottom: 10px;
  }
}
.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240,242,245, 1);
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.empty_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    margin-top: 30%;
    width: 80%;
  }
}
.btn_container{
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 56px;
  height: 50px;
  border-radius: 6px;
  background-color: #ff4d4f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  font-size: 12px;
  z-index: 99999;
  i{
    font-size: 22px;
    margin-top: 3px;
  }
}
</style>
